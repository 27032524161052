import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive( {
	selector: '[scroll]'
} )
export class ScrollDirective {
	@HostListener( 'window:scroll', [ '$event' ] )
	private onScroll( $event: Event ) {
		console.log( $event );
	}
}
