import { Component } from '@angular/core';
import { trigger, state, style, animate, transition, group, keyframes } from '@angular/animations';
import wait from 'timeout-as-promise';

const enum FormState {
	Form = 'form',
	Location = 'location',
	Loading = 'loading',
	Success = 'success'
}

async function getPosition( options?: PositionOptions ) {
	if( typeof ga === 'function' ) ga( 'send', {
		hitType: 'event',
		eventCategory: 'Geolocation',
		eventAction: 'Request'
	} );
	if( !navigator.geolocation ) {
		if( typeof ga === 'function' ) ga( 'send', {
			hitType: 'event',
			eventCategory: 'Geolocation',
			eventAction: 'Not Supported'
		} );
		throw new Error( 'Geolocation not supported' );
	}
	return await new Promise<GeolocationPosition>( ( resolve, reject ) => {
		navigator.geolocation.getCurrentPosition( location => {
			if( typeof ga === 'function' ) ga( 'send', {
				hitType: 'event',
				eventCategory: 'Geolocation',
				eventAction: 'Success'
			} );
			resolve( location );
		}, err => {
			if( typeof ga === 'function' ) ga( 'send', {
				hitType: 'event',
				eventCategory: 'Geolocation',
				eventAction: 'Fail',
				eventLabel: `${err.code}: ${err.message}`
			} );

			reject( err );
		}, options );
	} );
}

interface SignupServiceResponse {
	success: boolean;
}

@Component( {
	selector: 'signup-updates',
	styleUrls: [ './signup-updates.scss' ],
	templateUrl: './signup-updates.html',
	animations: [
		trigger( 'slide', [
			state( 'void', style( { opacity: 0, height: 0 } ) ),
			state( 'active', style( { opacity: 1, height: '*' } ) ),
			transition( '* => *', animate( '500ms ease-out' ) )
		] ),
		trigger( 'rotate', [
			state( 'void', style( { opacity: 0, transform: 'rotate3d(0,1,0,90deg)' } ) ),
			state( 'active', style( { opacity: 1, transform: 'rotate3d(0,1,0,0deg)' } ) ),
			transition( 'void => active', animate( '500ms ease-in' ) ),
			transition( 'active => void',
				animate( '500ms ease-out', style( { opacity: 0, transform: 'rotate3d(0,1,0,-90deg)' } ) )
			)
		] )
	]
} )
export class SignupUpdatesComponent {
	public email = '';
	public accept = false;
	public error = false;

	public state: FormState = FormState.Form;

	public get formState() { return this.state === FormState.Form ? 'active' : 'void'; }
	public get locationState() { return this.state === FormState.Location ? 'active' : 'void'; }
	public get loadingState() { return this.state === FormState.Loading ? 'active' : 'void'; }
	public get successState() { return this.state === FormState.Success ? 'active' : 'void'; }

	public ngOnInit() {
	}

	public closeError() {
		if( typeof ga === 'function' ) ga( 'send', {
			hitType: 'event',
			eventCategory: 'Signup Updates',
			eventAction: 'Dismiss Error'
		} );
		this.error = false;
	}

	public async onSubmit() {
		const { email, accept } = this;

		if( typeof ga === 'function' ) ga( 'send', {
			hitType: 'event',
			eventCategory: 'Signup Updates',
			eventAction: 'Submit'
		} );
		this.error = false;
		this.state = FormState.Location;

		async function send( email: string, accept: boolean, location: [ number, number ]|null ): Promise<SignupServiceResponse> {
			const response = await fetch( '/en-us/api/subscribe/updates', {
				method: 'PUT',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify( {
					email,
					accept,
					location
				} )
			} );

			if( !response.ok ) return { success: false };
			return await response.json();
		}

		let pre = send( email, accept, null );

		let location: [ number, number ]|null;
		try {
			const position = await getPosition( { timeout: 5 * 60 * 1000, maximumAge: 15 * 60 * 1000 } );
			location = [ position.coords.latitude, position.coords.longitude ];
		} catch( ex ) {
			location = null;
		}

		this.state = FormState.Loading;

		let { success } = await pre;
		if( location ) {
			( { success } = await send( email, accept, location ) );
		}

		if( success ) {
			this.state = FormState.Success;
			if( typeof ga === 'function' ) ga( 'send', {
				hitType: 'event',
				eventCategory: 'Signup Updates',
				eventAction: 'Success'
			} );
		} else {
			if( typeof ga === 'function' ) ga( 'send', {
				hitType: 'event',
				eventCategory: 'Signup Updates',
				eventAction: 'Error'
			} );
			this.error = true;
			await wait( 250 );
			this.state = FormState.Form;
		}
	}
}
