import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CookieComplianceComponent, SignupUpdatesComponent } from '../components';
import { ScrollDirective } from '../directives';


@NgModule( {
	imports: [ BrowserModule, BrowserAnimationsModule, FormsModule ],
	declarations: [
		CookieComplianceComponent,
		ScrollDirective,
		SignupUpdatesComponent
	],
	bootstrap: [ CookieComplianceComponent, SignupUpdatesComponent ]
} )
export class AppModule { }
