import "~polyfills";
import "~vendor";

import { enableProdMode } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { platformBrowser } from '@angular/platform-browser';
import { AppModule } from './app/app.module';

declare var process;
if( process.env.ENV === 'production' ) {
	enableProdMode();
}
platformBrowser().bootstrapModule( AppModule );
