import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import cookie from 'js-cookie';

const secure = location.protocol === 'https:',
	cookieName = 'cookies-accepted';

@Component( {
	selector: 'cookie-compliance',
	styleUrls: [ './cookie-compliance.scss' ],
	templateUrl: './cookie-compliance.html',
	animations: [
		trigger( 'animation', [
			state( 'void', style( { opacity: 0.25, transform: 'rotate3d(1,0,0,45deg) translate(0,100%)' } ) ),
			state( 'active', style( { opacity: 1, transform: 'rotate3d(0,0,1,0deg) translate(0,0)' } ) ),
			transition( '* => *', animate( '400ms ease-in' ) )
		] )
	]
} )
export class CookieComplianceComponent {
	public accepted: boolean;

	public ngOnInit() {
		this.accepted = !!cookie.get( cookieName, { secure: true, expires: 30 } );
		if( !this.accepted ) {
			if( typeof ga === 'function' ) ga( 'send', {
				hitType: 'event',
				eventCategory: 'Cookie Compliance',
				eventAction: 'Show'
			} );
		}
	}

	public accept() {
		this.accepted = true;
		cookie.set( cookieName, true );

		if( typeof ga === 'function' ) ga( 'send', {
			hitType: 'event',
			eventCategory: 'Cookie Compliance',
			eventAction: 'Dismiss'
		} );
	}
}
